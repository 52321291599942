<template>
  <navigation index="category" ref="navigation">
    <div class="topnavigation" style="margin: 5px 0px">
      <el-button
        type="primary"
        @click="
          OpenCategory({
            Sort: 0,
            Description: '',
            Name: '',
            Images: '',
            TaxRate: 21,
          })
        "
      >
        {{ tools.GetLanguageValue("web.新增") }}</el-button
      >
    </div>
    <div :style="{ height: ContentHeight + 'px' }">
      <el-table border :data="CategoryDatas" style="width: 100%; height: 100%">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column
          :label="tools.GetLanguageValue('web.姓名')"
          width="180"
          prop="Name"
        />
        <el-table-column
          :label="tools.GetLanguageValue('web.排序')"
          width="100"
          prop="Sort"
        />
        <el-table-column
          :label="tools.GetLanguageValue('web.税率')"
          width="100"
          prop="TaxRate"
        />
        <!-- <el-table-column
          :label="tools.GetLanguageValue('web.图片')"
          width="130"
        >
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-image
                width="60"
                height="60"
                :src="scope.row.ImagesFormat"
                class="avatar"
              />
            </div>
          </template>
        </el-table-column> -->
        <el-table-column
          :label="tools.GetLanguageValue('web.备注')"
          width="180"
          prop="Description"
        />
        <el-table-column
          :label="tools.GetLanguageValue('web.操作')"
          width="280"
        >
          <template #default="scope">
            <el-button
              type="success"
              size="small"
              @click="OpenCategory(scope.row)"
            >
              {{ tools.GetLanguageValue("web.编辑") }}</el-button
            >
            <el-button
              size="small"
              @click="DeleteCategory(scope.row)"
              type="danger"
            >
              {{ tools.GetLanguageValue("web.删除") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div
      style="
        height: 50px;
        display: flex;
        justify-items: center;
        gap: 10px;
        padding: 0px 10px;
      "
    >
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="CurrentChange"
        :page-size="CategoryPadding.Size"
        :total="CategoryPadding.Count"
      >
      </el-pagination>
    </div>

    <!-- 添加 -->
    <el-dialog
      v-model="OpenCategoryWindow"
      :title="tools.GetLanguageValue('web.编辑分类')"
      width="700px"
      :show-close="false"
    >
      <el-tabs v-model="LanguageName">
        <el-tab-pane :label="tools.GetLanguageValue('web.默认')" name="first">
          <el-form
            ref="form"
            :model="CategoryOpt"
            label-width="80px"
            style="margin-top: 10px"
          >
            <el-form-item :label="tools.GetLanguageValue('web.分类名称')">
              <el-input
                v-model="CategoryOpt.Name"
                @focus="InputSelect"
                :placeholder="tools.GetLanguageValue('web.请输入备注')"
              ></el-input>
            </el-form-item>
      
            <el-form-item :label="tools.GetLanguageValue('web.排序')">
              <el-input-number
                v-model="CategoryOpt.Sort"
                @focus="InputSelect"
                :min="0"
                :max="100000"
              ></el-input-number>
            </el-form-item>

            <el-form-item :label="tools.GetLanguageValue('web.税率')">
              <el-input-number
                v-model="CategoryOpt.TaxRate"
                @focus="InputSelect"
                :min="0"
                :max="100"
              ></el-input-number>
            </el-form-item>

            <el-form-item :label="tools.GetLanguageValue('web.备注')">
              <el-input
                v-model="CategoryOpt.Description"
                @focus="InputSelect"
                :placeholder="tools.GetLanguageValue('web.请输入备注')"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane
          :label="item.LanguageName"
          :name="item.LanguageName"
          v-for="(item, index) in CategoryOpt.Languages"
          :key="index"
        >
          <el-form-item :label="tools.GetLanguageValue('web.商品名称')">
            <el-input
              v-model="item.Language.Name"
              @focus="InputSelect"
            ></el-input>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="OpenCategoryWindow = false">
            {{ tools.GetLanguageValue("web.关闭") }}</el-button
          >
          <el-button @click="AddCategory" type="primary">
            {{ tools.GetLanguageValue("web.保存") }}</el-button
          >
        </span>
      </template>
    </el-dialog>
  </navigation>
</template>
<script >
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import navigation from "../../../components/Modules/Navigation.vue";
import PictureCover from "../../../components/PictureCover.vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { CloseBold, Plus } from "@element-plus/icons-vue";

export default {
  // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types aboutus
  data() {
    return {
      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,
      LanguageName: "first",
      CategoryOpt: {},
      CategoryDatas: [],
      OpenCategoryWindow: false,
      CategoryPadding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
      UploadPictureImageUrl: "",
      headersImage: {},
      ContentHeight: 800,
      tools: tools,
    };
  },
  name: "App",
  mounted() {
    this.UploadPictureImageUrl = axios.GetUrl() + "/api/Upload/ImageFileWebp";
    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header"),
    };

    this.GetCategory();

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
        this.ContentHeight = document.documentElement.clientHeight - 130;
        this.$refs.navigation.onresize();
      })();
    };
    this.ContentHeight = this.clientHeight - 130;
  },

  components: {
    navigation,
    PictureCover,
    CloseBold,
    Plus,
  },
  methods: {
    // 上传成功操作
    UploadSuccess(response, file, fileList) {
      if (response.Code == 200) {
        this.ComImageUrl = response.Data.FileName;
        this.ComImageFormet = response.Data.ImageFormet;
        this.CategoryOpt.ImagesFormat = response.Data.ImageFormet;
        this.CategoryOpt.Images = response.Data.FileName;
      }
    },

    InputSelect(e) {
      e.currentTarget.select();
    },
    //删除分类
    DeleteCategory(item) {
      ElMessageBox.confirm(
        "确定要删除<span style='color:red;font-size:14pt'>[" +
          item.Name +
          "]</span>吗" +
          "?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          const param = {};
          axios.apiMethod(
            "/stores/GoodsManage/DeleteCategory",
            "get",
            { id: item.Id },
            (result) => {
              if (result.Data.IsSuccess) {
                this.GetCategory();
              } else {
                ElMessageBox({
                  message: result.FullMsg,
                  type: "error",
                });
              }
            }
          );
        })
        .catch(() => {
          // catch error
        });
    },
    OpenCategory(item) {
      const param = {};
      axios.apiMethod(
        "/stores/GoodsManage/GetCategoryDetail",
        "get",
        { categoryId: item.Id },
        (result) => {
          this.CategoryOpt = result.Data;
          this.OpenCategoryWindow = true;
        }
      );
    },
    AddCategory(item) {
      const param = {};
      axios.apiMethod(
        "/stores/GoodsManage/InsertCategory",
        "post",
        this.CategoryOpt,
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetCategory();
            this.OpenCategoryWindow = false;
          } else {
            ElMessageBox({
              message: result.Msg,
              type: "error",
            });
          }
        }
      );
    },
    GetCategory() {
      const param = {};
      axios.apiMethod(
        "/stores/GoodsManage/GetCategory",
        "post",
        this.CategoryPadding,
        (result) => {
          if (result.Code == 200) {
            this.CategoryDatas = result.Data.Datas;
            this.CategoryPadding.Page = result.Data.Page;
            this.CategoryPadding.Size = result.Data.Size;
            this.CategoryPadding.Count = result.Data.Count;
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.editorContent {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.AboutusContent {
  max-width: 1600px;
  margin: 0 auto;
  padding: 30px;
}
</style>
